<template>
  <div class="upload-avatar">
    <div class="d-flex align-center">
      <TTBtn
        v-if="!showAvatar"
        :loading="loading"
        :disabled="loading"
        :data-test-label="dataTestLabelBtn"
        fab
        depressed
        large
        color="tt-secondary"
        @click="onClick"
      >
        <VIcon>fal fa-plus</VIcon>
      </TTBtn>
      <VAvatar
        v-else
        size="46"
        class="cursor--pointer"
      >
        <VImg
          :src="src"
          @click="onClick"
        />
      </VAvatar>
      <div class="ml-4">
        <h3
          v-if="!showAvatar"
          class="tt-text-body-2 tt-light-mono-46--text"
        >
          JPEG, PNG или GIF.<br>Макс. размер – 15 МБ.
        </h3>
        <div v-else>
          <div class="tt-black--text text--lighten-2">
            {{ truncateString(fileName, 20) }}
          </div>
          <div
            v-if="mandatory"
            class="cursor--pointer info--text"
            @click="onClick"
          >
            Заменить
          </div>
          <div
            v-else
            class="cursor--pointer error--text"
            @click="reset"
          >
            <VIcon
              class="mr-1"
              :size="12"
              color="error"
            >
              fal fa-trash-alt
            </VIcon>
            Удалить
          </div>
        </div>
      </div>
    </div>
    <input
      v-show="false"
      ref="input"
      accept="image/jpeg,image/png,image/gif"
      type="file"
      @input="onInput"
    >
    <div
      v-if="hasError"
      class="v-messages error--text mt-2"
      role="alert"
    >
      <div class="v-messages__wrapper">
        <template v-if="Array.isArray(errorMessagesAttr)">
          <div
            v-for="(errorMsg, i) in errorMessagesAttr"
            :key="i"
            class="v-messages__message"
          >
            {{ errorMsg }}
          </div>
        </template>
        <div
          v-else
          class="v-messages__message"
        >
          {{ errorMessagesAttr }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { fileToBase64, truncateString } from '@/utils';

export default {
  name: 'UploadAvatar',
  props: {
    value: {
      type: String,
      required: true,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: () => ([]),
    },
    dataTestLabelBtn: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    loading: false,
    src: '',
    fileName: 'Изображение',
    internalError: '',
  }),
  computed: {
    showAvatar() {
      return !!this.src && !this.loading;
    },
    hasError() {
      return Array.isArray(this.errorMessagesAttr)
        ? Boolean(this.errorMessagesAttr.length) : Boolean(this.errorMessagesAttr);
    },
    errorMessagesAttr() {
      if (!this.internalError) return this.errorMessages;
      if (Array.isArray(this.errorMessages)) {
        return this.errorMessages[0]
          ? [...this.errorMessages, this.internalError] : this.internalError;
      }
      return this.errorMessages ? [this.errorMessages, this.internalError] : this.internalError;
    },
  },
  created() {
    if (this.value) {
      // ToDo: Раскомментировать, когда бэкенд научится сохранять имена файлов
      // if (this.value.indexOf('http') !== -1) {
      //   // Вытаскиваем из URL имя файла
      //   const fileName = new URL(this.value);
      //   this.fileName = decodeURI(fileName.pathname.split('/').pop());
      // } else {
      //   this.fileName = this.value;
      // }
      this.src = this.value;
    }
  },
  methods: {
    truncateString,
    onClick() {
      this.$refs.input.click();
    },
    reset() {
      this.internalError = '';
      this.fileName = '';
      this.src = '';
      this.$emit('input', '');
    },
    async onInput(event) {
      this.loading = true;
      this.reset();
      const { target } = event;
      if (target.files.length > 0) {
        if (target.files[0].size > 15 * 1024 * 1024) {
          this.internalError = 'Размер файла не должен превышать 15 Мб';
          this.loading = false;
          return;
        }
        // ToDo: Раскомментировать, когда бэкенд научится сохранять имена файлов
        // this.fileName = target.files[0].name;
        this.fileName = 'Изображение';
        this.src = await fileToBase64(target);
        const base64 = this.src.substring(this.src.indexOf('base64,') + 7);
        this.$emit('input', base64);
      }
      this.loading = false;
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
